import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';

import Button from '../components/atoms/inputs/Button';
import BaseLayout from '../components/templates/BaseLayout';
import SEO from '../components/atoms/utility/seo';

function NotFoundPage() {
  const loaderTimerRef = React.useRef();
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    loaderTimerRef.current = setTimeout(() => {
      setShowLoader(false);
    }, 5000);
    return () => {
      clearTimeout(loaderTimerRef.current);
    };
  }, []);

  if (showLoader) {
    return (
      <BaseLayout>
        <div className="font-arial mx-auto w-full flex flex-col items-center justify-center h-full py-20">
          <div className="animate-pulse h-20 w-full rounded-full bg-slate-300"></div>
          <div className="animate-pulse h-12 w-full rounded-full bg-slate-300 mt-12"></div>
          <div className="animate-pulse h-12 w-24 rounded-full bg-slate-300 mt-4"></div>
        </div>
      </BaseLayout>
    );
  }

  return (
    <BaseLayout>
      <SEO title="Page Not Found" />
      <div className="font-arial mx-auto w-full flex flex-col items-center justify-center h-full py-20">
        <h1 className="hidden">Page Not Found</h1>
        <h2 className="md:text-lg lg:text-xl mb-12">
          Sorry we couldn't find what you were looking for.
        </h2>
        <Link to="/">
          <Button label="Return Home" />
        </Link>
      </div>
    </BaseLayout>
  );
}

export default NotFoundPage;
